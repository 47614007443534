.BlockUIContainer,
.BlockUIContent {
  position: relative;
  z-index: 10;
}

.BlockUIContent.isLoading {
  position: relative;
  z-index: 10;
  pointer-events: none;
  user-select: none;
}

.BlockUILoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
  user-select: none;
}

.BlockUILoading svg {
  width: 2rem;
  height: 2rem;
  color: rgba(67, 35, 0, 0.2);
  fill: #432300;
  animation: spin 1s linear infinite;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes spin {
  to {
    transform: rotate(1turn)
  }
}
