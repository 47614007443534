.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}
.react-datepicker-2home {
  display: flex;
  position: absolute;
  z-index: 999;
}