* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: 'Barlow', sans-serif !important;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(8px, -9px) scale(0.9) !important;
  font-weight: 500;
}

.button-focus:focus{
  outline: none;
}

.ql-container {
  height: auto !important;
}